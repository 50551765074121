import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const users = ref([])
const filteredUsers = ref([])

export default function useUsers() {
  // Use toast
  const toast = useToast()

  const refUsersTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'Photo' },
    { key: 'profile.fullName', label: 'Name', sortable: true },
    { key: 'profile.emailAddress', label: 'Email' },
    { key: 'phone', sortable: true },
    { key: 'isSuspended', label: 'Status', sortable: true},
    { key: 'createdAt', label: 'Signedup On', sortable: true},
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)


  const dataMeta = computed(() => {
    const localItemsCount = refUsersTable.value ? refUsersTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUsersTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
     const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = users.value.filter(
      user =>
        user.status.toLowerCase() == (statusFilter.value || user.status?.toLowerCase()) &&
       ( user.profile?.fullName?.toLowerCase().includes(queryLowered) || user.phone?.toLowerCase().includes(queryLowered) ||
       user.profile?.emailAddress?.toLowerCase().includes(queryLowered)))
    //     /* eslint-enable  */
        
    //     // const sortedData = filteredData.sort(sortCompare(sortBy))
    //     // if (sortDesc) sortedData.reverse()
    filteredUsers.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalUsers.value = filteredData.length
    refetchData()
  })

  function setUser(user) {
    users.value = users.value.filter(x => x.token != user.token);
    users.value.push(user);
    users.value.sort(function (a, b) {
      return a.id - b.id;
    });
  }

  function getUser(id) {
    return users.value.find(x => (x.id == id || x.token == id));
  }


  const fetchUsers = (ctx) => {
    if(users.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.USERS)
      .then(({data}) => {
        data.forEach((d) => {
          d.status = d.isSuspended ? 'Inactive' : 'Active'
        });

        users.value = data.sort((a, b) => a.updatedAt - b.updatedAt);

        filteredUsers.value = paginateArray(users.value, perPage.value, currentPage.value)
        totalUsers.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  
  const updateSuspensionStatus = (token) => {
    useJwt.axiosIns.post(`${routes.UPDATE_USER_SUSP_STATUS}/${token}/toggle-suspension`, {})
      .then(({data}) => {
        if(data) {
          let user = getUser(token);
          if(user) {
            user.isSuspended = !user.isSuspended;
            user.status = user.isSuspended ?  "Inactive":"Active"
            setUser(user)
          }
        }
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating user suspension',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveSuspensionStatusVariant = status => {
    if (status === 'Inactive') return 'warning'
    if (status === 'Active') return 'success'
    return 'primary'
  }

  
  const resolvePhoto = photo => {
    return photo?.url ?? ""
  }

  return {
    fetchUsers,
    updateSuspensionStatus,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    userCount: computed(() => users.value.length),
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUsersTable,

    resolveSuspensionStatusVariant,
    refetchData,
    resolvePhoto,

    // Extra Filters,
    statusFilter,
    users: computed(() => filteredUsers.value)
  }
}
