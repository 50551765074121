<template>
  <b-row class="match-height">
    <b-col
      xl="2"
      md="4"
      sm="6"
    >
      <statistic-card-vertical
        icon="EyeIcon"
        :statistic="(stats.total || 0).toLocaleString()"
        statistic-title="Riders"
        color="info"
      />
    </b-col>
    <b-col
      xl="2"
      md="4"
      sm="6"
    >
      <statistic-card-vertical
        color="warning"
        icon="MessageSquareIcon"
        :statistic="(userCount || 0).toLocaleString()"
        statistic-title="Users"
      />
    </b-col>
    <b-col
      xl="2"
      md="4"
      sm="6"
    >
    <statistic-card-vertical
      color="danger"
      icon="ShoppingBagIcon"
      :statistic="(tripCount || 0).toLocaleString()"
      statistic-title="Orders"
    />
    </b-col>
    <b-col
      xl="2"
      md="4"
      sm="6"
    >
      <statistic-card-vertical
        color="primary"
        icon="HeartIcon"
        :statistic="(onlineCount  || 0).toLocaleString()"
        statistic-title="Riders Online"
      />
    </b-col>
    <b-col
      xl="2"
      md="4"
      sm="6"
    >
      <statistic-card-vertical
        color="success"
        icon="AwardIcon"
        :statistic="kFormatter(earningCount) || 0"
        statistic-title="Revenue"
      />
    </b-col>
    <!-- <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="TruckIcon"
          statistic="2.1k"
          statistic-title=""
        />
      </b-col> -->
  </b-row>
</template>

<script>
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { mapState, mapActions } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import useTrip from '../apps/trip/useTrip';
import useUsers from '../apps/user/useUsers';
import useEarning from '../apps/earning/useEarning'
import useOnlineRider from '../apps/driver/track-driver/useOnlineRider'
import { avatarText, kFormatter } from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  methods: {
    ...mapActions('rider', ['fetchStats']),
  },
  computed: {
    ...mapState({
      stats: state => state.rider.stats,
    }),
  },
  mounted() {
    this.fetchStats()
  },
  setup() {
    onMounted(() => {
      fetchTrips();
      fetchUsers();
      fetchEarnings();
      fetchRidersOnline();
    })

    const {
      fetchTrips,
      tripCount
    } = useTrip()

    const {
      fetchUsers,
      userCount
    } = useUsers()

    const {
      fetchEarnings,
      earningCount
    } = useEarning()

    const {
      fetchRidersOnline,
      onlineCount
    } = useOnlineRider()
    

    return {
      tripCount,
      userCount,
      earningCount,
      onlineCount,
      kFormatter
    }
  }
}
</script>

<style>

</style>
